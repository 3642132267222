//
// Error 3
//

// Initialization of global variables, mixins and functions
@import "../../init";

.error.error-3 {
	.error-title {
		font-size: 8.7rem !important;
	}
}

@include media-breakpoint-up(md) {
	.error.error-3 {
		.error-title {
			font-size: 15.7rem !important;
		}
	}
}
